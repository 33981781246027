<template>
  <section id="Dashboard" class="hero is-primary is-fullheight">
    <div class="hero-body">
      <div class="container">
        <h1 class="title">
          WELCOME TO BOS ADMIN
        </h1>
        <h2 class="subtitle">
          Manage your content through the platform.
        </h2>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Dashboard"
};
</script>
